import React from 'react';
import PlayerPage from './components/PlayerPage';


function App() {
  return (
    <div className="App">
      <PlayerPage></PlayerPage>
    </div>
  );
}

export default App;
